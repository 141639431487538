<template>
    <div>
        <div  class="box pa-30 reserve-box">
            <div class="index-item">
                <div class="list">
                    <h3>预约日期</h3>
                    <p>{{date}}</p>
                </div>
                <div>
                    <DatePicker ref="DatePicker" @getDate="getDate" v-model="date"></DatePicker>
                </div>
                <div class="list">
                    <h3>预约数量</h3>
                    <p><input  class="count" value="num" v-model="num" readonly/></p>
                </div>
            </div>
            <!-- 须知 -->
            <div class="index-item-msg">
                <div>
                    <h4>预约须知</h4>
                </div>
                <div v-for="item in msgList">
                    {{item}}
                </div>
            </div>
            <!-- 下一步 -->
            <div class="index-footer">
                <div>
                    <a href="javascript:;" @click="putReserve()">提交</a>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import DatePicker from "_c/datePicker/datePicker"
    // import {getQuantity,addOrder,faceRegister} from "@/api/appointment.js"
    import {Field, Uploader, SwitchCell, Panel, Cell, CellGroup, Button,Checkbox } from 'vant';
    // import "../../assets/css/ticket.css"
    export default {
        components: {
            DatePicker,
            [Field.name]: Field,
            [Uploader.name]: Uploader,
            [SwitchCell.name]: SwitchCell,
            [Panel.name]: Panel,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Button.name]: Button,
            [Checkbox.name]: Checkbox,
        },
        data() {
            return {
                date: '',
                num: 1,
                info: {userId: '', date: '', ticketsId: '',carInfo:'',traffic:'',scenicId:''},
                msgList: ['1、门票限量发放，全天24小时可预约，约满为限。',
                    '2、请您在已预约时间段内入园参观。',
                    '3、预约采用实名制，需登记预约人姓名、身份证件号、手机号码等信息。',
                    '4、每个有效证件预约同天当场次的门票一张。',
                    '5、游客可登录官方网站或拨打咨询电话查询具体参观信息。',
                    '6、如遇大型活动或特展，需对预约参观进行调整时，我馆将提前通过官方网站和微信微博向社会进行公示。'
                ],
            }
        },
        created: function () {
            setInterval(this.scroll, 4000)
            this.info.userId=this.$route.params.userId;
            this.info.scenicId=this.$route.params.scenicId;
        },
        methods: {
            getDate(date,ticketsId,dateStr) {
                this.info.date=date;
                this.info.ticketsId=ticketsId;
                this.date = dateStr
            },
            //验证信息是否可以添加
            putReserve() {
                if (this.info.ticketsId ==""||this.info.ticketsId ==null||this.info.date ==""||this.info.date ==null){
                    this.$toast('请选择场次');
                } else {
                    this.info.carInfo = decodeURI(this.$route.query.carInfo);
                    this.info.traffic = this.$route.query.traffic;
                    this.$post('/ticket/doSaveAppointment', this.info).then(res => {
                       if (res.flag ==0){
                           this.$router.replace({path: '/tourist/success/' + this.info.userId + "/" + this.$store.state.scenicId})
                       }
                    })
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
    @function vw($px) {
        @return $px / 7.5 + vw
    }
    .reserve-box{
        padding-bottom: 128px;
    }
    //   使用有效期
    .index-item{
        overflow: hidden;
        width: 100%;
        /*height: vw(346);*/
        background-color: #fff;
        border-radius: vw(10);
        /*margin-top: vw(29);*/
        box-sizing: border-box;
        >.list{
            height: vw(115);
            display: flex;
            justify-content: space-between;
            align-items: center;

            padding: 0 vw(26);
            h3{
                font-size: vw(34);
                color: #53545C;
                font-weight:700;
                >span{
                    color: #53545C;
                    margin-left: vw(21);
                }
            }
            span{
                font-size: vw(27);
                color: #818188;
            }
            input{
                font-size: vw(27);
                color: #818188;
                text-align: right;
                padding-right: vw(10);
                box-sizing: border-box;
            }
            .ticket a{
                display: inline-block;
                width: vw(135);
                height: 50px;
                border: 1px solid #DFE0E0;
                box-sizing: border-box;
                border-radius: 4px;
                margin-left: 18px;
                color: #D3D5DD;
                font-size: 27px;
                text-align: center;
                line-height: vw(50);
            }
            .ticket a.active{
                border: 1px solid #00C74F;
                color: #00C74F;
            }
            img{
                display: inline-block;
                width: vw(56);
                height: vw(56);
                vertical-align: middle;
            }

            .count{
                display: inline-block;
                min-width: vw(56);
                height: vw(56);
                text-align: right;
                line-height: vw(56);
                padding: 0 vw(10);
                font-size: vw(32);
                color: #53545C;
                border: 0;
                vertical-align: middle;
            }


        }
        >div:nth-of-type(1){
            border-bottom: 1px solid #ebebeb;
        }
        >div:nth-of-type(2){
            border-bottom: 0;
        }
    }
    //   预约须知
    .index-item-msg{
        width: 100%;
        background-color: #fff;
        border-radius: vw(10);
        margin-top: vw(29);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: vw(26);
        box-sizing: border-box;
        >div{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: vw(24);
            color: #818188;
            line-height: vw(36);
            // 须知
            h4{
                font-size:vw(32);
                height: vw(106);
                margin: 0;
                line-height: vw(80);
                font-weight:500;
                color:rgba(83,84,92,1);
            }
        }
    }
    // 下一步
    .index-footer{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: vw(20)  0;
        background-color: #fff;
        >div{
            padding: 0 vw(30);
            a{
                display: block;
                height:vw(98);
                line-height: vw(98);
                font-size: vw(32);
                background-color:#09C164 ;
                text-align: center;
                border-radius: vw(48);
                color: #fff;
            }
        }
    }
    //   预约记录
</style>
<style lang="scss">
    .reserve-box{
        .van-icon{
            font-size: vw(40);
        }
    }
</style>
