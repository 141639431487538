<template>
  <div class="zss-date-picker">
    <!--<input class="zss-date-input" type="text" placeholder="请选择日期" readonly v-model="currentValue" @focus="inputFocus" />-->
    <transition name="fade">
      <div class="zss-picker-model" @click.self="modelClick" :style="{'z-index':zIndex}">
        <div class="zss-panel">
          <!-- header -->
          <div class="zss-panel-header">
            <div class="zss-date-month">
              <div class="zss-date-prev zss-text-hover" @click="prevMonth"><img src="../../assets/images/arrow-left.png" alt=""></div>
              <div class="zss-date-text">{{firstDate[0]}}年</div>
              <div class="zss-date-text">{{firstDate[1]}}月</div>
              <div class="zss-date-next zss-text-hover" @click="nextMonth"><img src="../../assets/images/arrow-right.png" alt=""></div>
            </div>
          </div>
          <!-- /header -->

          <div class="zss-panel-body">
            <!-- 年 -->
            <div class="zss-year-box" v-show="select=='year'">
              <div class="zss-year" v-for="item in yearList" @click="clickYear(item)">{{item}}</div>
            </div>
            <div class="zss-year-control" v-show="select=='year'">
              <div class="zss-control-prev" @click="prevYears">＜</div>
              <div class="zss-control-next" @click="nextYears">＞</div>
            </div>
            <!-- /年 -->
            <!--月-->
            <div class="zss-month-box" v-show="select=='month'">
              <div class="zss-month" v-for="(item,index) in monthArray" @click="clickMonth(index)">{{item}}</div>
            </div>
            <!--/月-->
            <!-- 周标题 -->
            <div class="zss-date-week" v-show="select=='date'">
              <div class="zss-week" v-for="item in weekArray">{{item}}</div>
            </div>
            <!-- /周标题 -->
            <!-- 日期-->
            <div class="zss-date-box" v-show="select=='date'">
              <div class="zss-date" :class="{'zss-month-now':isMonth(item),active:ischeck(item)&&dateIndex==isDate,color:ischeck(item),'today-lineHeight':isNow(item)&&dateIndex==isDate}" v-for="(item,dateIndex) in dateArray" :key="dateIndex"   @click="clickDate(item,dateIndex)">
                {{item[2]}}
                <span class="today-style" v-if="isNow(item)">今天</span>
              </div>
            </div>
            <!--/日期-->
            <div class="choose-box">
              <div  v-for="(item ,index) in residueList" :key="index" :class="{active:isActive==index}" >
                <span :class="{active:item.type==0 ||item.residueNumber<=0}"  @click="choose(currentValue,item,index)"> <i>{{item.startTime}}-{{item.endTime}}<b v-if="item.type==1">余票:{{item.residueNumber}}张</b><b
                        v-else-if="item.type==0">未开放</b></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
  import momnet from 'moment'
  export default {
    props:{
      value:{
        type:String,
        default:'1990-01-15 下午(15:00-17:30)'
      }
    },
    data(){
      return {
        active:-1,
        currentValue:'', //当前值
        zIndex:-1,
        isShow:false,
        select:'date',
        testList:[1,2,3,4,5,6],
        weekArray:['日','一','二','三','四','五','六'],
        monthArray:['一月','二月','三月','四月','五月','六月','七月','八月','九月','十月','十一月','十二月'],
        currentDate:[], //当前选中日期
        dateArray:[],  //当月日期列表
        firstDate:[],  //当月1号
        yearList:[], //当前年列表
        isDate:-1,
        today:'',
        disparkDate:[],
        enterpriseId:'',
        residueList:[],
        isActive:-1
      }
    },
    created(){
      this.enterpriseId = this.$route.params.scenicId
    },
    mounted(){
      let data = {};
      data["enterpriseId"] = this.enterpriseId;
      data["userId"] = this.$route.params.userId;
      this.initData();
      let date = this.currentDate.join('-');
      this.$post('/ticket/getTicketQuantity', data).then(res => {
        this.disparkDate = res.object;
        if (this.disparkDate.length > 0) {
          this.residueList = res.object[0].ticketInfoList;
        }
      })
      this.today=this.currentDate.join('-')
    },
    methods:{
      //初始化值
      initData() {
        this.currentValue = this.value
        //当前选中日期
        this.currentDate = (this.value || momnet().format('YYYY-MM-DD')).split('-');
        //当月1号
        this.firstDate = momnet(this.currentDate.join('-')).startOf('month').format('YYYY-MM-DD').split('-');
        this.getDateArray();
        this.getYearArray();
        this.getIndex();
      },
      //获取当前页面的最大Index
      getIndex() {
        let zIndex = -1;
        let array = [...document.all];
        for (let i = 0; i < array.length; i++) {
          let index = window.getComputedStyle(array[i], null).zIndex;
          if (index != 'auto' && index > zIndex) {
            zIndex = parseInt(index);
          }
        }
        this.zIndex = zIndex + 1;
      },
      //获取当前显示日期列表
      getDateArray() {
        let day = momnet(this.firstDate.join('-')).day(); //1号是周几
        let list = []
        for (var i = 0; i < 42; i++) {
          let date = null;
          if (day == 0) {
            date = momnet(this.firstDate.join('-')).add((i - 7), 'days');
          } else {
            date = momnet(this.firstDate.join('-')).add((i - day), 'days');
          }
          list.push(date.format('YYYY-MM-DD').split('-'));
        }
        this.dateArray = list;
        //    渲染票的时候 可以 把这里改 成 this.dateArray=[0]=list    余量 this.dateArray[1]=余量   循环出来就可以了
      },
      //获取当前年列表
      getYearArray() {
        let year = this.firstDate[0];
        let yearList = [];
        for (let i = 0; i < 15; i++) {
          yearList.push(year - 7 + i);
        }
        this.yearList = yearList;
      },
      //获取焦点
      inputFocus() {
        this.initData();
        this.isShow = true;
      },
      //点击遮罩层
      modelClick() {
        this.isShow = false;
      },
      //获取当前日期
      clickDate(item, dateIndex) {
        let date = item.join('-');
        if (this.isifday(date)) {
          this.currentValue = date;
          this.isDate = dateIndex
          for (let i = 0; i < this.disparkDate.length; i++) {
            if (this.currentValue == this.disparkDate[i].date) {
              this.residueList = this.disparkDate[i].ticketInfoList;
            }
          }
        }
      },
      choose(currentValue, item,index) {
        if (item.type == 0 || item.residueNumber <= 0) {
          return;
        }
        if (currentValue == '') {
          currentValue = this.disparkDate[0].date;
        }
        let currentStr = currentValue + '(' + item.startTime + '—' + item.endTime + ')'
        this.$emit('getDate', currentValue, item.ticketsId,currentStr);
        this.isActive =index
        this.isShow = false;
      },
      //判断是否是当天
      isNow(item) {
        if (this.today == item.join('-')) {
          return true;
        }
      },
      //判断是否当月
      isMonth(item) {
        if (item[0] != this.firstDate[0]) {
          return false;
        }
        if (item[1] != this.firstDate[1]) {
          return false;
        }
        return true;
      },
      // 判断是否是开放的日期
      ischeck(item) {
        let date = item[0] + "-" + item[1] + "-" + item[2];
        return this.isifday(date);
      },
      isifday(date) {
        for (let i = 0; i < this.disparkDate.length; i++) {
          if (date == this.disparkDate[i].date && this.disparkDate[i].type) {
            if (this.disparkDate[i].residueNumber <= 0) {
              return false;
            }
            return true;
          }
        }
        return false;
      },
      // 判断是否是当天之后的所有日期
      isNextMounth(item) {
        let date = item[0] + "-" + item[1] + "-" + item[2];
        return this.isifday(date);
      },

      //点击前一个月
      prevMonth() {
        this.isDate = -1
        let prevMonthDate = momnet(this.firstDate.join('-')).startOf('month').add(-1, 'month');
        this.firstDate = prevMonthDate.format('YYYY-MM-DD').split('-');
        this.getDateArray();
      },
      //点击下一个月
      nextMonth() {
        this.isDate = -1
        let nextMonthDate = momnet(this.firstDate.join('-')).startOf('month').add(1, 'month');
        this.firstDate = nextMonthDate.format('YYYY-MM-DD').split('-');
        this.getDateArray();
      },
      //点击前一年
      prevYear() {
        this.isDate = -1
        let prevYearDate = momnet(this.firstDate.join('-')).startOf('month').add(-1, 'year');
        this.firstDate = prevYearDate.format('YYYY-MM-DD').split('-');
        this.getDateArray();
      },
      //点击下一年
      nextYear() {
        this.isDate = -1
        let nextYearDate = momnet(this.firstDate.join('-')).startOf('month').add(1, 'year');
        this.firstDate = nextYearDate.format('YYYY-MM-DD').split('-');
        this.getDateArray();
      },
      //点击上一列表年
      prevYears() {
        let yearList = [];
        for (let i = 0; i < this.yearList.length; i++) {
          yearList.push(this.yearList[i] - 15);
        }
        this.yearList = yearList;
      },
      //点击下一列表
      nextYears() {
        let yearList = [];
        for (let i = 0; i < this.yearList.length; i++) {
          yearList.push(this.yearList[i] + 15);
        }
        this.yearList = yearList;
      },
      //点击年
      // clickYear(item){
      //     let firstDate = this.firstDate;
      //     firstDate.splice(0,1,item+'');
      //     this.select = 'month';
      //     this.getDateArray();
      // },
      // clickMonth(index){
      //     let month = index+1;
      //     month = month<10?'0'+month:''+month;
      //     let firstDate = this.firstDate;
      //     firstDate.splice(1,1,month);
      //     this.select = 'date';
      //     this.getDateArray();
      // }
    }
  }
</script>
<style lang="scss" scoped>
  @function vw($px) {
    @return $px / 7.5 + vw
  }
  .fade-enter-active, .fade-leave-active {
    transition: all .3s
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
    transform: scale(1.2);
  }
  .zss-date-picker{
    display: inline-block;
    box-sizing: border-box;
    /*width: vw(480);*/
    /*height: vw(40);*/
    font-family: Arial, Helvetica, sans-serif;
    text-align: right;
      width: 100%;
  }
  .zss-date-picker >.zss-date-input{
    width: 100%;
    height: 100%;
    text-align: right;
    border: 0;
    box-sizing: border-box;
    padding: 0 vw(20);
    outline: none;
    font-size: vw(28);
    color: #09C164;
  }
  .zss-date-picker >.zss-date-input::-webkit-input-placeholder {
    color: #818188;
    font-size: vw(28);
    text-align: right;
  }
  .zss-date-picker > .zss-picker-model{
    /*position: fixed;*/
    /*left: 0;*/
    /*top: 0;*/
    /*width: 100%;*/
    /*height: 100%;*/
    /*display: flex;*/
    /*align-items: center;*/
    /*background: rgba(0,0,0,.5);*/
  }
  .zss-date-picker > .zss-picker-model > .zss-panel{
    /*position: absolute;*/
    /*left: 0;*/
    /*bottom: 0;*/
    /*border-radius: vw(20);*/
    /*border-bottom-left-radius: 0;*/
    /*border-bottom-right-radius: 0;*/
    box-sizing: border-box;
    width: 100%;
    height: vw(760);
    background: #FFF;
    padding: vw(10);
    border-bottom: 1px solid #ebebeb;
    /*box-shadow: 0 vw(10) vw(20) rgba(0,0,0,.2);*/
  }
  .zss-panel > .zss-panel-header{
    display: flex;
    height: vw(90);
    line-height: vw(90);
    font-size: vw(36);
    font-weight: 700;
    color: #53545C;
    text-align: center;
    justify-content: space-around;
    align-items: center;
  }
  .zss-date-month{
    display: flex;
    align-items: center;
    position: relative;
    background: #FFF;
    height: vw(60);
    margin-left:1px;
    text-align: center;
    img{
      width: vw(28);
    }
  }
  .zss-date-prev{
    position: absolute;
    left: vw(-70);
    /*top: 50%;*/
    width: vw(20);
    margin-top: vw(5);
    cursor: pointer;
  }
  .zss-date-text{
    display: inline-block;
    cursor: pointer;
  }
  .zss-date-next{
    position: absolute;
    /*top: 50%;*/
    right: vw(-50);
    cursor: pointer;
    width: vw(20);
    margin-top: vw(5);
  }
  .zss-text-hover{
    transition: color .3s;
  }
  .zss-text-hover:hover{
    color: #25b864;
  }
  .zss-text-disabled{
    color: #E6E6E6;
  }
  .zss-panel-body{
    padding:0 vw(30);
    overflow: hidden;
  }
  .zss-date-week{
    display: flex;
  }
  .zss-week{
    width: vw(100);
    line-height: vw(50);
    font-size: vw(28);
    text-align: center;
    color:#414141;
  }
  .zss-date-box{
    padding-bottom: vw(20);
    overflow: hidden;
  }
  .zss-date{
    position: relative;
    float: left;
    width: vw(61);
    height: vw(61);
    margin: vw(6) vw(17);
    border-radius: 50%;
    text-align: center;
    line-height: vw(65);
    cursor: pointer;
    transition: all .3s;
    color: #C5C5C5;
    font-size: vw(28);
  }
  /* 当天选中后 修改行高 */
  .today-lineHeight{
    line-height: vw(40);
  }
  /* 日期选中样式 */
  .zss-date.active{
    background: #25b864;
    color: #FFF!important;
  }
  /* 当天选中的样式 */
  .zss-date.active .today-style{
    bottom: 1px;
    left: 0;
    color: #FFF;
  }
  .zss-date.color{
    color:#333;
  }
  /* 今天 样式 */
  .today-style{
    position: absolute;
    bottom:vw(-10);
    left: 0;
    width: 100%;
    font-size: vw(20);
    line-height: vw(20);
    color: #25b864;
  }

  .zss-month-now{
    color: #C5C5C5;
  }
  .zss-month-now:hover{
    /* color: #25b864; */
  }
  .zss-date-now{
    background: #25b864;
    color: #FFF;
  }
  .zss-date-now:hover{
    color: #FFF;
  }
  .zss-year-box{
    overflow: hidden;
  }
  .zss-year{
    float: left;
    width: vw(98);
    height: vw(100);
    line-height: vw(100);
    cursor: pointer;
    text-align: center;
  }
  .zss-year:hover{
    color: #25b864;
  }
  .zss-year-control{
    display: flex;
    height: vw(40);
    line-height: vw(40);
  }
  .zss-control-prev{
    flex: 1;
    cursor: pointer;
  }
  .zss-control-prev:hover{
    color: #25b864;
  }
  .zss-control-next{
    flex: 1;
    cursor: pointer;
  }
  .zss-control-next:hover{
    color: #25b864;
  }
  .zss-month-box{
    overflow: hidden;
    padding: vw(30) 0;
  }
  .zss-month{
    width: vw(122.5);
    height: vw(50);
    float: left;
    cursor: pointer;
  }
  .choose-box{
    margin-top: vw(10);
    width: 100%;
    text-align: center;
    overflow-x: auto;
    display: flex;
    /*justify-content: space-between;*/
  }
  .choose-box > div{
    /*display: table-cell;*/
    margin-right: vw(20);
    margin-bottom: vw(20);
    &.active span{
      background-color: #09C164;
      i{
        color: #fff;
        b{
          color: #fff;
        }
      }

    }
  }
  .choose-box span{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: vw(280);
    height: vw(100);
    padding: 0 vw(20);
    box-sizing: border-box;
    border:1px solid #09C164;
    border-radius: vw(100);
    text-align: center;
  }
  span.active{
      border-color: #818181;
      color: #818181;
  }
 span.active i{
     color: #818181!important;
 }
  span.active b {
     color: #818181!important;
 }
  .choose-box span i:nth-of-type(1){
    color: #09C164;
    font-size: vw(28);
  }
  .choose-box span i{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: vw(20);
    color: #818188;
    line-height: vw(22);
  }
  .choose-box span i b{
    color:#53545C;
    margin-top: vw(10);
  }
</style>


